<template>
  <section class="container form-container">
    <form ref="form" class="card table-list">
      <h3>{{$t('CreateToken')}}</h3>
      <label>{{$t('TokenLogo')}}</label>
      <div :class="['upload-pic',{uploaded:img}]" id="fileContainer" :style="'backgroundImage:url('+ (img?'https://oss-us-cdn.maiziqianbao.net/'+img:require('static/img/icon/add.png'))+')'">
        <div id="file"></div>
        <input type="hidden" name="img" v-model="img">
      </div>
      <label ref="name">{{$t('TokenName')}}</label>
      <input type="text" v-model="name" :placeholder="$t('TokenName')" @keydown.enter.prevent>
      <label ref="symbol">{{$t('TokenSymbol')}}</label>
      <input type="text" v-model="symbol" :placeholder="$t('TokenSymbol')" @keydown.enter.prevent>
      <label ref="totalSupply">{{$t('TotalSupply')}}</label>
      <input type="number" title="" v-model="totalSupply" :placeholder="$t('TotalSupply')" @keydown.enter.prevent>
      <label>{{$t('Decimals')}}</label>
      <input type="number" v-model="decimals" disabled>
      <template v-if="account">
        <label>{{$t('OwnerAddress')}}</label>
        <input type="text" v-model="account" disabled>
      </template>
      <!-- <ul class="radio">
        <li @click="isBurn=!isBurn" :class="{active:isBurn}">{{$t('TokenBurn')}}</li>
        <li @click="isMint=!isMint" :class="{active:isMint}">{{$t('TokenMint')}}</li>
      </ul> -->
      <p class="text-center">
        <button v-if="account" @click.prevent="createToken">{{$t('Create')}}</button>
        <button v-else @click.prevent="connectWallet">{{$t('ConnectWallet')}}</button>
      </p>
      <!-- <p class="text-12">Solidity Contract Code: </p> -->
      <label>Solidity Contract Code: </label>
      <a class="link" href="https://github.com/mathwallet/MathChain-Contracts/blob/main/Contracts/erc20.sol" target="_blank" rel="noopener noreferrer">
        https://github.com/mathwallet/MathChain-Contracts/blob/main/Contracts/erc20.sol
      </a>
    </form>
    <Loading :loading="loading"/>
  </section>
</template>
<script>
import Loading from "base/loading";
import OssUploader from 'static/js/oss.js'
import evmjs from 'static/js/evm.js'
export default {
  components:{Loading},
  data(){
    return {
      account:"",
      loading:false,
      img:"",
      symbol:"",
      name:"",
      totalSupply:"",
      decimals:18,
      isChecked:1,
      isBurn: false,
      isMint: false,
      icon:'',
    }
  },
  mounted(){
    // 上传图片
    if(this.$refs.form){
      OssUploader.initUploader('mathchainExplorer').then(([img,icon])=>{
        this.img = img;
        this.icon = icon;
      }).catch((err)=>{
        console.log(err);
      })
    }
  },
  methods:{
    async connectWallet(){
      this.account = await evmjs.connectWallet().catch(err=>{
        this.catchERR(err)
      })
    },
    validate() {
      if (!this.name) {
        this.$toast(this.$t('Enter')+this.$t('TokenName'));
        window.scrollTo(0, this.$refs.name.offsetTop );
        return false;
      }
      if (!this.symbol.trim()) {
        this.$toast(this.$t('Enter')+this.$t('TokenSymbol'));
        window.scrollTo(0, this.$refs.symbol.offsetTop);
        return false;
      }
      if (this.totalSupply<=0) {
        this.$toast(this.$t('Enter')+this.$t('TotalSupply'));
        window.scrollTo(0, this.$refs.totalSupply.offsetTop );
        return false;
      }
      return true;
    },
    // 提交表单
    async createToken() {
      if (!this.validate()) {
        return false;
      }

      this.loading = true;
      let totalSupply = this.webUtil.BN(this.totalSupply).times(Math.pow(10,this.decimals)).toFixed(0);
      let args=[this.name,this.symbol,totalSupply];

      evmjs.deployContract(args).then(contract=>{
        let data = {
          contract_address:contract,
          name:this.name,
          symbol:this.symbol,
          total_supply:totalSupply,
          decimals:this.decimals,
          icon:this.icon,
        }
        this.axios.post(this.domain + "evm/tokens/save_information",data).then(res=>{
          this.loading = false;
          if (res.data.code==0) {
            this.$toast(this.$t('SubmitSuccess')).then(()=>{
              this.$router.push('/tokens');
            })
          } else {
            this.$toast(res?.data?.message,3000);
          }
        })
      }).catch(err=>{
        this.loading = false;
        console.log(err);
        this.$toast(err?err?.message:this.$t('noMathExtension'),3000);
      })
    },
  }
}
</script>
<style scoped>
.link{
  display: block;
  padding-bottom:20px;
  font-size: 12px;
  color: var(--blueColor);
}
</style>