class OssUploader{
  constructor() {
    this.g_object_name_type='random_name';
    this.g_object_name ='';
    this.accessid='';
    this.host='';
    this.policyBase64='';
    this.signature='';
    this.callbackbody='';
    this.key='';
    this.expire = 0;
    this.flag = '';
    this.img_name = '';
  }

  send_request() {
    var xmlhttp = null;
    if (window.XMLHttpRequest) {
      xmlhttp = new XMLHttpRequest();
    } else if (window.ActiveXObject) {
      xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
    }

    if (xmlhttp != null) {
      let serverUrl = 'https://api.maiziqianbao.net/apiTools/getOssUploadPolicy?flag='+this.flag

      xmlhttp.open("GET", serverUrl, false);
      xmlhttp.send(null);
      return xmlhttp.responseText
    } else {
      alert("Your browser does not support XMLHTTP.");
    }
  }
  get_signature() {
    // 可以判断当前expire是否超过了当前时间， 如果超过了当前时间， 就重新取一下，3s 作为缓冲。
    let now = Date.parse(new Date()) / 1000;
    if (this.expire < now + 3) {
      let body = this.send_request()
      let obj = eval("(" + body + ")");
      this.host = obj['host']
      this.policyBase64 = obj['policy']
      this.accessid = obj['accessid']
      this.signature = obj['signature']
      this.expire = parseInt(obj['expire'])
      this.callbackbody = obj['callback']
      this.key = obj['dir']
      return true;
    }
    return false;
  }
  set_upload_param(up, filename, ret) {
    if (ret == false) {
      ret = this.get_signature()
    }
    this.g_object_name = this.key;
    if (filename != '') {
      this.calculate_object_name(filename)
    }
    let new_multipart_params = {
      'key': this.g_object_name,
      'policy': this.policyBase64,
      'OSSAccessKeyId': this.accessid,
      'success_action_status': '200', //让服务端返回200,不然，默认会返回204
      'callback': this.callbackbody,
      'signature': this.signature,
    };

    up.setOption({
      'url': this.host,
      'multipart_params': new_multipart_params
    });
    up.start();
  }

  get_uploaded_object_name(filename) {
    if (this.g_object_name_type == 'local_name') {
      let tmp_name = this.g_object_name
      tmp_name = tmp_name.replace("${filename}", filename);
      return tmp_name
    } else if (this.g_object_name_type == 'random_name') {
      return this.g_object_name
    }
  }
  random_string(len) {
    len = len || 32;
    let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    let maxPos = chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
      pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  }
  get_suffix(filename) {
    let pos = filename.lastIndexOf('.'),
    suffix = ''
    if (pos != -1) {
      suffix = filename.substring(pos)
    }
    return suffix;
  }
  calculate_object_name(filename) {
    if (this.g_object_name_type == 'local_name') {
      this.g_object_name += "${filename}"
    } else if (this.g_object_name_type == 'random_name') {
      let suffix = this.get_suffix( filename )
      this.img_name = this.random_string( 20 ) + suffix;
      this.g_object_name = this.key + this.img_name;
    }
    return ''
  }
  // oss安全上传
  initUploader( flag ) {
    this.flag = flag;
    return new Promise( resolve => {
      var uploader = new plupload.Uploader({
        runtimes: 'html5,flash,silverlight,html4',
        browse_button: 'file',
        //multi_selection: false,
        container: document.getElementById('fileContainer'),
        flash_swf_url: './plugin/Moxie.swf',
        silverlight_xap_url: './plugin/Moxie.xap',
        url: 'http://oss.aliyuncs.com',
        filters: {
          mime_types: [ //只允许上传图片和zip文件
            { title: "Image files", extensions: "jpg,jpeg,gif,png,bmp" },
          ],
          max_file_size: '2mb', //最大只能上传2mb的文件
          prevent_duplicates: true //不允许选取重复文件
        },
        init: {
          FilesAdded: (up, files)=> {
            this.set_upload_param(uploader, '', false);
          },
          BeforeUpload:(up, file)=> {
            this.set_upload_param( up, file.name, true );
          },
          FileUploaded:(up, file, info)=>{
            if (info.status == 200) {
              let img = this.get_uploaded_object_name( file.name );
              resolve([img,this.img_name])
            } else if (info.status == 203) {
              console.log('上传到OSS成功，但是oss访问用户设置的上传回调服务器失败，失败原因是:' + info.response);
            } else {
              console.log(info.response);
            }
          },
          Error: function ( up, err ) {
            if (err.code == -600) {
              alert('图片太大，请小于 2M');
            } else if (err.code == -601) {
              alert('图片类型有误，仅支持 jpg,jpeg,gif,png,bmp');
            } else if (err.code == -602) {
              alert('图片已上传');
            } else {
              alert(err.response||err.message);
              console.log(err.response);
            }
          }
        }
      });
      uploader.init();
    })

  }
}
export default new OssUploader();